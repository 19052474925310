import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '26, 88, 156',
		'primary-dark': '0, 47, 108',
		'accent': '36, 162, 233',
		'accent-plus': '255, 255, 255',
	},
});
